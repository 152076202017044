// ** React Imports
import { Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { store } from './redux/store'
import { Provider } from 'react-redux'
import { Spinner, Center } from "@chakra-ui/react"
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import ScrollToTop from '@components/layout/ScrollToTop'
import "./instrument";
import App from './App'

// ** Lazy load app
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      retry: 1
    }
  }
})

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <BrowserRouter>
    
    <Provider store={store}>
      <Suspense fallback={<Center h='50%'> <Spinner size='xl' /></Center>}>
        <QueryClientProvider client={queryClient}>
          <ScrollToTop />
          <App />
        </QueryClientProvider>
      </Suspense>
    </Provider>
  </BrowserRouter>
)

